import { Button, Card, CardContent, Chip, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './nuevaObra-jss'
import SelectNew from '../Forms/Select'
import { ButtonAction } from "../../atoms"
import { Add, Delete } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames'

const StepEquipoLiterario = ({ classes, updateValues, titles, roles, escritores, data, titleRoles, canViewEquipoLit }) => {
    const handleAddRemove = (type, versionKey, equipoKey) => {
        const temp = [...data];
        if (type === "version") {
            if (versionKey !== undefined) {
                temp.splice(versionKey, 1);
            } else {
                temp.push({ 
                    version_equipo: "", 
                    value: "", 
                    type: "version", 
                    equiposLiterarios: [] 
                });
            }
        } else if (type === "equipo") {
            if (equipoKey !== undefined) {
                temp[versionKey].equiposLiterarios.splice(equipoKey, 1);
            } else {
                temp[versionKey].equiposLiterarios.push({ version_equipo: "", value: "" });
            }
        }
        updateValues(temp);
    };

    const handleChange = (event, versionKey, equipoKey) => {
        const temp = [...data];
    
        if (equipoKey !== undefined) {
            temp[versionKey].equiposLiterarios[equipoKey][event.target.name] = event.target.value;
        } else {
            temp[versionKey][event.target.name] = event.target.value;
        }
    
        updateValues(temp);
    };
    

    const handleChip = (value, key) => {
        if (canViewEquipoLit) {
            const temp = data;
            temp[key].value = value;
            updateValues(temp)
        }

    }

    return (
        <section className={classes.bodyForm}>
            <Card>
                <CardContent>
                    <Grid container justify='between' alignItems='center' spacing={16} className={classes.contentContainer}>
                        <Grid item xs={12} sm={12}>
                            <Typography className={classes.stepHeadingExpand}>Equipos Literarios</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant= "contained"
                                color="primary"
                                size="small"
                                disabled={!canViewEquipoLit}
                                className={classes.button}
                                onClick={() => { handleAddRemove("version") }}>
                                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Añadir versión
                            </Button>
                        </Grid>
                    </Grid>
    
                    {data && data.map((version, versionKey) => (
                        <Card key={versionKey} style={{ marginTop: 12 }}>
                            <CardContent>
                                <Grid container spacing={16} className={classes.contentContainer}>
                                    <Grid item xs={12} sm={6}>
                                        <div key={versionKey} className={classNames(classes.flexCell, classes.dataCell, versionKey % 2 && classes.oddCell)}>
                                            <div className={classes.fieldContainer}>
                                                <div className={classes.flexCell}>
                                                    <FormControl fullWidth>
                                                        <SelectNew
                                                            label="Otras versiones"
                                                            name="version_equipo"
                                                            isClearable={false}
                                                            defaultValue={version.version_equipo}
                                                            classes={classes}
                                                            options={[...titles, { value: "-1", data: "Otras", label: "Otras" }]}
                                                            value={version.version_equipo}
                                                            onChange={(e) => {
                                                                const customEvent = {
                                                                    target: {
                                                                        name: "version_equipo",
                                                                        value: e.label === "Otras" ? "-1" : e.label,
                                                                    }
                                                                }
                                                                handleChange(customEvent, versionKey)
                                                            }}
                                                            isDisabled={!canViewEquipoLit}
                                                            placeholder=""
                                                        />
                                                        {version.version_equipo_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                                    </FormControl>
                                                    <div className={classes.actionPadding}>
                                                        <ButtonAction
                                                            rounded
                                                            color="default"
                                                            disabled={!canViewEquipoLit}
                                                            className={classes.buttonRemove}
                                                            onClick={() => { handleAddRemove("version", versionKey) }}>
                                                            <Delete />
                                                        </ButtonAction>
                                                    </div>

                                                </div>
                                                {version.version_equipo === -1 && 
                                                    <div>
                                                        <TextField
                                                            label="Escribe la obra"
                                                            name="description"
                                                            fullWidth
                                                            defaultValue=""
                                                            margin="normal"
                                                            disabled={!canViewEquipoLit}
                                                            value={version.description}
                                                            onChange={(e) => handleChange(e, versionKey)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            error={version.description_err}
                                                        />
                                                        {version.description_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                                    </div>}
                                            </div>
                                            <div className={classNames([classes.flexCell, classes.fieldContainer, classes.cellPaddingTop, classes.chipContainer])}>
                                                <Chip label="Primigenia" className={[getChipClassNames(classes, 'Primigenia'), version.value === "Primigenia" ? classes.chipPrimigenia : null]} onClick={() => handleChip("Primigenia", versionKey)} />
                                                <Chip label="Adaptación" className={[getChipClassNames(classes, 'Adaptación'), version.value === "Adaptación" ? classes.chipAdaptacion : null]} onClick={() => handleChip("Adaptación", versionKey)} />
                                                {version.value_err ? <FormHelperText className={classes.errorHelperColor}>Selecciona una opción</FormHelperText> : null}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {version.equiposLiterarios.map((equipo, equipoKey) => (
                                            <div key={equipoKey} className={classNames(classes.flexCell, classes.dataCell, equipoKey % 2 && classes.oddCell)}>
                                                <div className={classes.fieldContainer}>
                                                    <div className={classes.flexCell}>
                                                        <FormControl fullWidth>
                                                            <InputLabel shrink>
                                                                Equipo Literario
                                                            </InputLabel>
                                                            <Select
                                                                name="version_equipo"
                                                                disabled={!canViewEquipoLit}
                                                                onChange={(e) => handleChange(e, versionKey, equipoKey)}
                                                                value={equipo.version_equipo}
                                                                className={classes.selectEmpty}
                                                                displayEmpty
                                                                error={equipo.version_equipo_err}
                                                            >
                                                                {
                                                                    escritores.map((e) => (
                                                                        <MenuItem key={`rol_${e.value}`} value={e.label}> {e.label} </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                            {equipo.version_equipo_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                                        </FormControl>
                                                        <div className={classes.actionPadding}>
                                                            <ButtonAction
                                                                rounded
                                                                color="default"
                                                                disabled={!canViewEquipoLit}
                                                                className={classes.buttonRemove}
                                                                onClick={() => { handleAddRemove("equipo", versionKey, equipoKey) }}>
                                                                <Delete />
                                                            </ButtonAction>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.fieldContainer}>
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink>
                                                            {titleRoles}
                                                        </InputLabel>
                                                        <Select
                                                            name="value"
                                                            disabled={!canViewEquipoLit}
                                                            onChange={(e) => handleChange(e, versionKey, equipoKey)}
                                                            value={equipo.value}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                            error={equipo.value_err}
                                                        >
                                                            {
                                                                roles.map((rol) => (
                                                                    <MenuItem key={`equipo_${rol.value}`} value={rol.data}> {rol.data} </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                        {equipo.value_err ? <FormHelperText className={classes.errorHelperColor}>Campo requerido</FormHelperText> : null}
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ))}
                                        <div className={classNames(classes.actionPaddingTop, classes.justifyEnd)}>
                                            <Button
                                                variant= "contained"
                                                color="default"
                                                size="small"
                                                disabled={!canViewEquipoLit}
                                                className={classes.buttonAdd}
                                                onClick={() => { handleAddRemove("equipo", versionKey) }}>
                                                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Añadir equipo literario
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </CardContent>
            </Card>
        </section>
    );    
}

StepEquipoLiterario.propTypes = {
    updateValues: PropTypes.func.isRequired
}

export default withStyles(styles)(StepEquipoLiterario);


/**
 * Returns the corresponding color for a `Clasificacion` value.
 * 
 *  ventas,
 *  produccion,
 *  adptation -> adaptacion,
 *  original -> primigenia,
 *  vigente,
 *  vencida,
 *  porVencer,
 *  produccionTelevisa,
 *  produccionExtranjera,
 *  noProducida,
 *  inedita
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} clasificacion 
 * @returns {string} Corresponding color for the `clasificacion`.
 */
function getChipClassNames(classes, clasificacion) {

    let color = null;
    let base = classes.chipBase;

    switch (clasificacion) {
        case 'Primigenia':
            base = [classes.chipTipo];
            // color = classes.chipPrimigenia
            break;
        case 'Adaptación':
            base = [classes.chipTipo];
            // color = classes.chipAdaptacion
            break;
        default:
            color = classes.chipDefault;
            break;
    }

    return classNames(
        base,
        color
    );
}